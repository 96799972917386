<template>
  <div>
    <!-- 搜索区域 -->
    <el-form>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="请选择审核状态">
            <el-select placeholder="请选择审核状态" v-model="search.status">
              <el-option label="全部" :value="''" />
              <el-option label="待审核" :value="0" />
              <el-option label="审核通过" :value="1" />
              <el-option label="审核未通过" :value="2" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="请输入学生姓名">
            <el-input v-model="search.stu_name"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="请输入学生身份证号">
            <el-input v-model="search.id_card"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="9">
          <el-form-item label="请选择审核时间">
            <el-date-picker
              v-model="startEnd"
              type="daterange"
              start-placeholder="开始时间"
              range-separator="-"
              end-placeholder="结束时间"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
            />
          </el-form-item>
        </el-col>
        <el-col :span="1">
          <el-button type="primary" @click="onRefundSearchBtn">
            搜索
          </el-button>
        </el-col>

        <el-col :span="1">
          <el-button
            style="margin-left: 20px"
            :loading="loading"
            type="success"
            @click="onPayTheFeesexport"
          >
            导出
          </el-button>
        </el-col>
      </el-row>
    </el-form>

    <!-- 表格区域 -->
    <el-table
      :data="data.tableData"
      border
      style="width: 100%; margin-top: 20px"
      stripe
      :header-cell-style="{ background: '#f2f2f2' }"
    >
      <!-- <el-table-column type="selection" width="55"   /> -->
      <el-table-column fixed type="index" label="序号" width="60" />
      <!-- <el-table-column fixed prop="order_id" label="订单号" width="240" /> -->
      <el-table-column fixed prop="stu_name" label="学生姓名" width="100" />
      <el-table-column fixed prop="id_card" label="身份证号" width="180" />
      <!-- <el-table-column prop="college_name" label="系部" width="150" /> -->
      <el-table-column prop="college_name" label="系部" width="150">
        <template v-slot="{ row }">
          <span v-if="row.college_name === 'undefined'"> </span>
          <span v-else>
            {{ row.college_name }}
          </span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="domain_name" label="专业" width="220" /> -->
      <el-table-column prop="domain_name" label="专业" width="150">
        <template v-slot="{ row }">
          <span v-if="row.domain_name === 'undefined'"> </span>
          <span v-else>
            {{ row.domain_name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="grade" label="年级" width="180" />
      <!-- <el-table-column
        prop="class_name"
        label="班级"
        width="180"
        v-if="class_name !== 'undefined' || class_name !== null"
        z
      /> -->
      <el-table-column prop="class_name" label="班级" width="150">
        <template v-slot="{ row }">
          <span v-if="row.class_name === 'undefined'"> </span>
          <span v-else>
            {{ row.class_name }}
          </span>
        </template>
      </el-table-column>
	<el-table-column prop="payee_name" label="收款人姓名" width="180" />
      <el-table-column prop="bank" label="开户行" width="180" />
	<el-table-column prop="sub_branch" label="支行" width="180"/>
      <el-table-column prop="bank_card" label="卡号" width="180" />
      <el-table-column prop="phone" label="联系方式" width="180" />
      <el-table-column prop="refund_reason" label="退费原因" width="180" />
      <el-table-column
        prop="zhaosheng_status"
        label="招生处审核状态"
        width="180"
      >
        <template v-slot="{ row }">
          <span v-if="row.zhaosheng_status === 0">待审核</span>
          <span v-else-if="row.zhaosheng_status === 1">审核通过</span>
          <span v-else-if="row.zhaosheng_status === 2">审核未通过</span>
        </template>
      </el-table-column>
      <el-table-column prop="zhaosheng_time" label="招生审核时间" width="180" />
      <el-table-column prop="status" label="财务审核状态" width="180">
        <template v-slot="{ row }">
          <span v-if="row.status === 0">待审核</span>
          <span v-else-if="row.status === 1">审核通过</span>
          <span v-else-if="row.status === 2">审核未通过</span>
        </template>
      </el-table-column>
      <el-table-column prop="end_time" label="财务审核时间" width="180" />
      <el-table-column prop="created_at" label="提交时间" width="180" />

      <el-table-column fixed="right" label="操作" width="200">
        <template #default="{ row }">
          <!-- <el-button
            type="primary"
            size="small"
            @click="onEditBtn(row.id)"
            v-if="row.zhaosheng_status === 0 && data.roleData === 0"
          >
            招生审核
          </el-button>
          <span
            type="primary"
            size="small"
            v-if="row.zhaosheng_status === 1 && data.roleData === 0"
          >
            审核通过
          </span>
          <span
            type="primary"
            size="small"
            v-if="row.zhaosheng_status === 2 && data.roleData === 0"
          >
            已驳回
          </span>
          <el-button
            type="primary"
            size="small"
            @click="onEditBtnC(row.id)"
            v-if="row.status === 0 && data.roleData === 0"
          >
            财务审核
          </el-button>
          <span
            type="primary"
            size="small"
            v-if="row.status === 1 && data.roleData === 0"
          >
            审核通过
          </span>
          <span
            type="primary"
            size="small"
            v-if="row.status === 2 && data.roleData === 0"
          >
            已驳回
          </span> -->
          <el-button
            type="primary"
            size="small"
            @click="onEditBtn(row.id)"
            v-if="row.zhaosheng_status === 0 && data.roleData === 1"
          >
            审核
          </el-button>
          <span
            type="primary"
            size="small"
            v-if="row.zhaosheng_status === 1 && data.roleData === 1"
          >
            审核通过
          </span>
          <span
            type="primary"
            size="small"
            v-if="row.zhaosheng_status === 2 && data.roleData === 1"
          >
            审核未通过
          </span>
          <el-button
            type="primary"
            size="small"
            @click="onEditBtnC(row.id)"
            v-if="row.status === 0 && data.roleData === 2"
          >
            审核
          </el-button>
          <span
            type="primary"
            size="small"
            v-if="row.status === 1 && data.roleData === 2"
          >
            审核通过
          </span>
          <span
            type="primary"
            size="small"
            v-if="row.status === 2 && data.roleData === 2"
          >
            审核未通过
          </span>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-row style="margin-top: 20px">
      <el-col>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size="search.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="search.total"
        >
        </el-pagination>
      </el-col>
    </el-row>

    <refund-examine-from
      v-model="detailVisible"
      :orderid="id"
      :onPayOrderList="onPayOrderList"
    ></refund-examine-from>
    <refund-caiwu-from
      v-model="detailcaiwuVisible"
      :orderid="idc"
      :onPayOrderList="onPayOrderList"
    >
    </refund-caiwu-from>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { refundlistAPI, reportRefundAPI } from '@/api/finance'
import refundExamineFrom from './components/refundExamineFrom.vue'
import refundCaiwuFrom from './components/refundCaiwuFrom.vue'
// import { userSCornerListAPI } from '@/api/organization'
import { userStore } from '@/store'
/** 搜索 S */
const search = ref({
  page: 1,
  total: 0,
  limit: 10,
  status: '',
  stu_name: '', // 学生姓名
  id_card: '', // 学生身份证号
  userid: '',
  role: '',
  start_time: '',
  end_time: ''
})

const store = userStore()
// const userRoleTitleList = ref([])
// const userSCornerList = async () => {
//   const res = await userSCornerListAPI({ id: store.userid })
//   console.log('res', res)
//   // 数据回显
//   userRoleTitleList.value = res.map((item) => item.role_name)
// }

/** 搜索 E */
const startEnd = ref([new Date(''), new Date('')])
watch(
  () => startEnd,
  (val) => {
    if (!val.value) {
      search.value.start_time = ''
      search.value.end_time = ''
    } else {
      search.value.start_time = val.value[0]
      search.value.end_time = val.value[1]
    }
  },
  {
    deep: true
  }
)
const onRefundSearchBtn = () => {
  // search.value.start_time = startEnd.value[0]
  // search.value.end_time = startEnd.value[1]
  onPayOrderList()
}
// 退费搜索
// const onRefundSearchBtn = () => {
//   onPayOrderList()
//   console.log('this.store', store.userid)
//   console.log('search.value.limit', search.value.limit)
// }

/** 表格 S */
// 表格数据
const data = ref({
  tableData: [],
  roleData: []
})
// const tableData = ref([])
// const roleData = ref([])
const onPayOrderList = async () => {
  search.value.userid = store.userid
  const { list, role } = await refundlistAPI(search.value)
  search.value.total = list.total
  data.value.roleData = role
  data.value.tableData = list.data

  console.log('data.list.data', data.value.tableData)
  console.log('total', list.total)
}
onPayOrderList()

// 审核
const detailVisible = ref(false)
const id = ref('')
const onEditBtn = (orderid) => {
  console.log('orderid', orderid)
  console.log(detailVisible.value)
  detailVisible.value = true
  id.value = orderid
}

const detailcaiwuVisible = ref(false)
const idc = ref('')
const onEditBtnC = (orderid) => {
  console.log('orderid', orderid)
  console.log(detailVisible.value)
  detailcaiwuVisible.value = true
  idc.value = orderid
}
/** 表格 E */
const loading = ref(false)
const onPayTheFeesexport = async () => {
  loading.value = true
  const data = await reportRefundAPI(search.value)
  window.location.href = `https://lnjsxy.college.yslts.com${data}`
  loading.value = false
}
/**
 *  分页 S
 */
// 每页获取多少数据
const handleSizeChange = (val) => {
  search.value.limit = val
  console.log('search.value.limit', search.value.limit)
  onPayOrderList()
}

// 页码值
const handleCurrentChange = (val) => {
  search.value.page = val
  console.log('search.value.page', search.value.page)
  onPayOrderList()
}

/**
 *  分页 E
 */
</script>

<style lang="scss" scoped></style>
