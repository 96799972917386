<template>
  <div>
    <el-dialog v-model="detailVisible" title="财务审核" width="45%">
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="120px"
        status-icon
      >
        <el-form-item label="是否通过">
          <el-select placeholder="请选择是否通过" v-model="ruleForm.status">
            <el-option label="通过" :value="1" />
            <el-option label="不通过" :value="2" />
          </el-select>
        </el-form-item>
        <el-form-item label="驳回原因" prop="demo">
          <el-input
            type="textarea"
            rows="4"
            style="width: 600px"
            v-model="ruleForm.demo"
          />
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="btnCancel">取消</el-button>
          <el-button type="primary" @click="btnConfirm">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from 'vue'
import { useVModel } from '@vueuse/core'
import { ElMessage } from 'element-plus'

import { refundExamineAPI } from '@/api/finance'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  orderid: String,
  onPayOrderList: {
    type: Function
  }
})

const detailVisible = useVModel(props)

const ruleForm = ref({
  demo: '',
  status: 1
})

defineEmits(['update:modelValue'])

const btnCancel = () => {
  // 在取消按钮点击时重置表单数据
  ruleForm.value.demo = ''
  ruleForm.value.status = 1
  detailVisible.value = false
}

const ruleFormRef = ref(null)

const rules = ref({
  demo: []
})
const btnConfirm = async () => {
  ruleFormRef.value.clearValidate() // 清除当前验证规则

  if (ruleForm.value.status === 2) {
    // 如果审核状态为不通过，设置驳回原因为必填
    const demoRule = {
      required: true,
      message: '请输入驳回原因',
      trigger: 'blur'
    }
    rules.value.demo = [demoRule]
  } else {
    // 如果审核状态为通过，取消驳回原因的必填
    rules.value.demo = []
  }

  if (!ruleFormRef.value || !(await ruleFormRef.value.validate())) {
    ElMessage.error('请检查表单')
    return
  }

  await refundExamineAPI({
    id: props.orderid,
    status: ruleForm.value.status,
    demo: ruleForm.value.demo
  })

  ElMessage.success('审核已提交')
  // 在确认按钮点击后重置表单数据
  ruleForm.value.demo = ''
  ruleForm.value.status = 1
  detailVisible.value = false
  props.onPayOrderList()
}
</script>

<style></style>
